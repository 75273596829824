import expr from './expressionEvaluation.js'
import utils_gen from './utils_gen.js'
import Vue from 'vue'

export default{
  status:{
    //bigger number will be the status of the entire survey record.
    //if necessary, fill the labels with traduction => done insise the components. acces to the store easier.
    0:{
      name:'filled-ok',
      input_class:'alert alert-success',
      label_class:'',
      list_group_class:''
    },
    2:{
      name:'filled-with-defalut', // if the default is a calculation and change, we change the value.
      input_class:'alert alert-success',
      label_class:'',
      list_group_class:''
    },
    4:{
      name:'not-filled-not-relevent',
      input_class:'alert alert-secondary',// should not be visible...
      label_class:'',
      list_group_class:''
    },
    5:{
      name:'not-filled-not-required',
      input_class:'alert alert-secondary',
      label_class:'',
      list_group_class:''
    },
    7:{
      name:'forced-ok-survey', // see status 31
      input_class:'alert alert-secondary',
      label_class:'',
      list_group_class:''
    },
    //next one have an input message, that indicate an error and will be visible to user.
    10:{
      name:'autocomplete-not-in-list',
      input_message:'notInList',
      input_class:'alert alert-warning',
      label_class:'text-warning',
      list_group_class:'list-group-item-warning'
    },
    11:{
      name:'flag-raised',
      input_message:'flagRaised',
      input_class:'alert alert-warning',
      label_class:'text-warning',
      list_group_class:'list-group-item-warning'
    },
    15:{
      name:'constraint-not-ok',
      input_message:'doNotRespectConstraint',
      input_class:'alert alert-warning',
      label_class:'text-warning',
      list_group_class:'list-group-item-warning'
    },
    20:{
      name:'required-empty',
      input_message:'required',
      input_class:'alert alert-danger',
      label_class:'text-danger',
      list_group_class:'list-group-item-danger'
    },
    31:{
      name:'forceFormToBeValid',
      // input_message:'force',
      input_class:'alert alert-success',
      label_class:'',
      list_group_class:'',
      acceptedInitialValues:[0,2],
      treeNewValue: 7,
    }
  },
  isRequired(el,myData,otherData,namepath){
    //check if this element has a required property.
    if(el.hasOwnProperty('bind')){
      if(el.bind.hasOwnProperty('required')){
        if(['yes','true','True','Yes','TRUE'].indexOf(el.bind.required)>-1){
          return true
        }else if(['no','false','False','No','FALSE'].indexOf(el.bind.required)>-1){
          // check to avoid to do expression check on those values
          return false
        }else{
          return expr.checkRelevant(myData,el.bind.required,otherData,namepath)
        }
      }
    }
    return false
  },
  isRelevant(el,myData,otherData,namepath){
    //check if this element is relevant
    if(el.hasOwnProperty('bind') && el.bind.hasOwnProperty('relevant')){
      return expr.checkRelevant(myData,el.bind.relevant,otherData,namepath)
    }
    return true
  },
  // getInitialStatus(el,myData,otherData,namepath){
  //   //the element is empty, but know if is required.
  //   if(this.isRequired(el,myData,otherData,namepath)){
  //     return 20
  //   }else{
  //     return 5
  //   }
  // },
  buildEmptySurveyData(obj1,form_definition, surveyDoc = {form_data:{}, form_status:{}}, baseNamePath=[]){
    //used both for creating complete new form and to create empty repeat element.
    obj1.form_data={}
    obj1.form_status={}
    this.buildEmptySurveyData_sub(form_definition.children, obj1.form_data,obj1.form_status,surveyDoc.form_data,form_definition,baseNamePath,{formDefinition: form_definition})
    return obj1
  },
  buildEmptySurveyData_sub(children,data,status,formData,formDefinition,namePath,otherData){
    //formData,formDefinition useful for getStatus on the default values.
    //namePath is useful for the last default value
    children.map(x=>{
      // this.log('****** build empty sub children '+x.name)
      // this.log(JSON.stringify(data))
      // this.log(JSON.stringify(status))
      const newNamePath=namePath.concat(x.name)
      if(x.hasOwnProperty('children')){
        //should always have a name if we have children.
        if(x.type=='repeat'){
          // this.log('** build empty repeat ' + x.name)
          data[x.name]=[]
          status[x.name]=[] //todo: don't know if possible a repeat is required. If yes, data model not adapted.
        }else{
          data[x.name]={}
          status[x.name]={} // group cannot required, children yes. so don't check, it has to hold an object that will be filled
          
          this.buildEmptySurveyData_sub(x.children,data[x.name],status[x.name],formData,formDefinition,newNamePath,otherData) // call ourself on sub group
          //this.log('** SUB get status ' + x.name)
          //this.log(status[x.name])
        }
      }else if(x.hasOwnProperty('name')){
        // value case
        data[x.name]=this.getDefaultValue(data[x.name],x,formData,formDefinition,otherData,newNamePath)
        //always check for the relevent...
        //TODO: Account that the relevent shold be done after all have neen create... could cause problem if fields are not in the right order...
        status[x.name]=this.getStatus(data[x.name],x,formData,formDefinition,otherData,newNamePath)
      }
      // this.log('****** build empty sub children - end '+x.name)
      // this.log(JSON.stringify(data))
      // this.log(JSON.stringify(status))
    })
    // this.log('**** build empty sub ')
    // this.log(JSON.stringify(data))
    // this.log(JSON.stringify(status))
  },
  hasConstraint(el){
    //check if this element has a required property.
    if(el.hasOwnProperty('bind')){
      if(el.bind.hasOwnProperty('constraint')){
        return true
      }
    }
    return false
  },
  isAutocomplete(el){
    //check if this element has a required property.
    if(el.hasOwnProperty('control')){
      if(el.control.hasOwnProperty('appearance')){
        if(el.control.appearance=='autocomplete'){
          return true
        }
      }
    }
    return false
  },
  isEmpty(val){
    let isEmpty=val?false:true
    if (Array.isArray(val) && val.length==0 ){
      isEmpty=true
    }
    if(val===false){
      return false
    }
    if(val+''===val){
      //string
      if(val.trim().length==0){
        return true
      }
    }
    if(val===0){
      return false
    }
    return isEmpty
  },
  getDefaultValue(val,el,formData,formDefinition,otherData,namePath){
    // single value
    //check if default value:
    if(el.hasOwnProperty('default')){
      let x=el
      //check if condition apply 
      if(x.condition){
        //check condition before do the calculation
        if(expr.evaluateExprString(formData,x.condition,otherData,namePath)===false){
          return undefined
        }
      }
      // check the default type
      if(typeof x.default === 'string'){ // we support default value as nubmers, we don't want to do indexOf on those, crash.
        if(x.default=='*last'){
          if (global.lastEditSurveyDoc){
            //special case, we take the lat value entered in the form.
            //or we take the last from the databse or the last in browser session...
            //last in browser session looks better as avoid conflicts if multiple edit on the web.
            const pLast=global.lastEditSurveyDoc.form_data
            try {
              return utils_gen.getPropTree(pLast,namePath) // null if not found. could verify if we want something else
            } catch (error) {
              return null
            }
          }//else no default value
          return null
        }else if(x.default.indexOf('*calculated@')!==-1){
          // default calculated value...
          let calc1=x.default.slice('*calculated@'.length)
          if(x.condition){
            // if we have a condition, it has to be valid
            if(expr.evaluateExprString(formData,x.condition,otherData,namePath)){
              return expr.evaluateExprString(formData,calc1,otherData,namePath)
            }
            return null
          }else{
            // if no condition, just return the calculated value
            return expr.evaluateExprString(formData,calc1,otherData,namePath)
          }
        }else if(x.default.indexOf('()')!==-1){ // for function like now() - supported in survey123
          return expr.evaluateExprString(formData,x.default,otherData,namePath)
        }
      }
      // normal case
      if(x.type=='integer' || x.type=='decimal'){
        return Number(x.default)
      }else{
        return x.default
      }
      
    }
    // not default
    return null
  },
  getStatus(val,el,formData,formDefinition,otherData,namePath){
    // if parentData, useful for repeat or group.
    // mostly for repeat as we first check there a prop exist there.
    // this.log('* getStatus '+el.name)
    //repeat cannot enter here.
    let isEmpty=this.isEmpty(val)

    // this.logStatus(el.name + ' - ' + isEmpty)

    //is relevant
    if(!this.isRelevant(el,formData,otherData,namePath)){
      this.logStatus(4)
      return 4
    }

    //required -
    if(this.isRequired(el,formData,otherData,namePath) && isEmpty){
      // should not be empty
      return 20
    }
    // geopoint
    if(el.type=='geopoint' && this.isRequired(el,formData,otherData,namePath)){ // has a value, not empty:
      if(!(val.hasOwnProperty('geometry') && val.geometry.hasOwnProperty('coordinates') && val.geometry.coordinates[0] && val.geometry.coordinates[1])){
        return 20
      }
    }
    //check constraint
    if(this.hasConstraint(el) && (isEmpty===false || el.type == 'note')){ // we already checd of the isEmpty and required condition
      // this.logStatus(el.name + ' - check constraint ' + expr.checkConstraint(val,el.bind.constraint,formData,otherData,namePath))
      let isValid=null
      try {
        isValid = expr.checkConstraint(val,el.bind.constraint,formData,otherData,namePath)
      } catch (error) {
        // checkConstraintalready has try catch... look like
        // console.log('below error is not evaluated, bug.')
        // console.error(error);
        isValid=false
      }
      if(!isValid){
        return 15
      }
    }
    // has a flag been raised and not solved?
    const namePathFlag = namePath.toSpliced(namePath.length-1, 1, namePath[namePath.length-1]+'__flag')
    const flagVal = utils_gen.getPropTree2(formData,namePathFlag)
    if(flagVal?.status == 'flag-raised'){
      return 11
    }
    // autocomplete in list?
    if(this.isAutocomplete(el)){
      let opt=[]
      if(el.hasOwnProperty('choices')){
        opt= el.choices
      }else if(el.hasOwnProperty('itemset')){
        opt= formDefinition.choices[el.itemset]
      }
      let choiceVal=opt.filter(x=>x.name==val)
      if(choiceVal.length==0){
        return 10
      }
    }
    // has a value.
    if(val){
      //if no defulat=>null so this condition cannot be null=null
      if(val===this.getDefaultValue(val,el,formData,formDefinition,otherData,namePath)){
        return 2 // this is the default value
      }
      return 0
    }
    if(el.type=='note'){
      return 0 // a note that is shown, must be valid, unless has constraint, but already taken care.
    }
    return 5
  },
  //Set the status for all the form. used inside or outside edit.vue
  getStatusData(formObject,formDefinition,otherData){
    if(!otherData.formDefinition){
      otherData.formDefinition=formDefinition
    }
    let form_data=formObject.form_data
    let form_status=formObject.form_status
    this.getStatusData_sub(formDefinition.children,form_data,form_status,form_data,formDefinition,[],otherData)
  },
  getStatusData_sub(children,data,status,formData,formDefinition,namePath,otherData){
    // when updating form, in the status, there might be reference to old name, remove them.
    const names = children.map(x=>{
      if(x.hasOwnProperty('name')){
        return x['name']
      }
    })
    Object.keys(status).map(x=>{
      if (names.indexOf(x) == -1){
        delete status[x]
      }
    })
    // check every children
    children.map(x=>{
      //todo: get the default value
      const newNamePath=namePath.concat(x.name)
      if(x.hasOwnProperty('children')){
        //should always have a name if we have children.
        if(x.type=='repeat'){
          //Verify if relevant...
          if(!this.isRelevant(x,formData,otherData,newNamePath)){
            //not relevant, put 4
            Vue.set(status,x.name,4)
            data[x.name]=null // if not relevant, erase data.
          }else{
            if(data[x.name]===null || Array.isArray(data[x.name]) == false){
              data[x.name]=[] // empty array, because was not relevant before. now it is.
            }
            if(data[x.name].length==0){
              //no element, verify if required
              if(this.isRequired(x,formData,otherData,newNamePath)){
                Vue.set(status,x.name,20)
              }else{
                Vue.set(status,x.name,5)
              }
            }else{
              //loop throw each of the repeat and get their status as array
              status[x.name]=data[x.name].map((y,y2)=>{
                const newNamePath2=namePath.concat([x.name,y2])
                const repeat_status={}
                this.getStatusData_sub(x.children,y,repeat_status,formData,formDefinition,newNamePath2,otherData) // call ourself on sub group
                return repeat_status
              })
            }
          }
        }else{ // it's a group
          if(!this.isRelevant(x,formData,otherData,newNamePath)){
            //not relevant, put 4 as value
            Vue.set(status,x.name,4)
            data[x.name]=null
          }else{
            if(status[x.name]===null || status[x.name]==4 || status[x.name]===undefined){
              //if the group is not defined...
              Vue.set(status,x.name,{})
            }
            if(!data[x.name]){
              // TODO: default, should be able to take into account calcultaed default... could have dependencies in the rest of the form...
              // const newEl=this.buildEmptySurveyData({},x)
              // this is the solution:
              let obj1={}
              obj1.form_data={}
              obj1.form_status={}
              this.buildEmptySurveyData_sub(x.children, obj1.form_data,obj1.form_status,formData,formDefinition,newNamePath,otherData)
              data[x.name]=obj1.form_data
            }
            this.getStatusData_sub(x.children,data[x.name],status[x.name],formData,formDefinition,newNamePath,otherData) // call ourself on sub group
          } 
        }
      }else if(x.hasOwnProperty('name')){
        let newStatus=this.getStatus(data[x.name],x,formData,formDefinition,otherData,newNamePath)
        // TODO: as we reset the status every time we redo , the status[x.name] should not be used - Verify...
        if(newStatus!=4 && (status[x.name]==4 || utils_gen.getPropTree2(otherData.oldSurveyStatus,newNamePath)==4)){ 
          // get the default value
          const defVal = this.getDefaultValue(data[x.name],x,formData,formDefinition,otherData,newNamePath)
          if(defVal){ // if we have a value
            data[x.name]=defVal
            newStatus=this.getStatus(data[x.name],x,formData,formDefinition,otherData,newNamePath)
          }
        }
        if(x.force_status_31 && this.status[31].acceptedInitialValues.indexOf(newStatus)!=-1 && expr.checkRelevant(formData,x.force_status_31,otherData,newNamePath)){
          newStatus=31
        }
        if(newStatus===0 && status[x.name]==2){
          //do nothing - keep that it's a default value
        }else if(newStatus!=status[x.name]){
          Vue.set(status,x.name,newStatus)
        }
        if(status[x.name]==4 && data[x.name]!== null){
          //not relevant put null
          data[x.name]=null
        }
      }
    })
  },
  getStatusOfTree(statusTree){
    // calculate the global status of a form or of a part of it... repeat for instance...
    if (isNaN(statusTree)==false){
      return statusTree
    }
    const statusValues=utils_gen.flattenValuesObject(statusTree)
    let rep = Math.max.apply(Math, statusValues)
    if (this.status?.[rep]?.treeNewValue){
      rep = this.status[rep].treeNewValue
    }
    return rep
  },
  //****** code to automatically propulate the _design geometries view with all geometric data */
  getDesignGeometryViews(formDefinition,options){
    //return an array of views(to be added to a desigh doc) that should be present so geometry can be in a view.
    if (! options){
      options={}
    }
    return this.getDesignGeometryViews_sub(formDefinition.children,formDefinition,[],options)
  },
  getDesignGeometryViews_sub(children,formDefinition,namePath,options){
    let views={}
    children.map(x=>{
      const newNamePath=namePath.slice()
      newNamePath.push(x.name)
      if(x.hasOwnProperty('children')){
        //should always have a name if we have children.
        if(x.type=='repeat'){
          const newNamePath2=newNamePath.slice()
          newNamePath2.push('repeat')
          let v1=this.getDesignGeometryViews_sub(x.children,formDefinition,newNamePath2,options) // call ourself on sub group
          views=Object.assign(views,v1)
        }else{
          let v1=this.getDesignGeometryViews_sub(x.children,formDefinition,newNamePath,options) // call ourself on sub group
          views=Object.assign(views,v1)
        }
      }else if(x.hasOwnProperty('name')){
        if(['geopoint','geotrace','geoshape'].indexOf(x.type)>-1){
          // **** we have geometry field
          //define the additional field
          const addFields=[]
          let map_info = 'doc._id'
          // addFields.push('geoJson.properties._id = doc._id') // we already have id_survey
          if(options.editListFieldsForm && options.editListFieldsForm.length>0){
            const s2=[]
            options.editListFieldsForm.map(x=>{
              const x2=x.join('.')
              addFields.push(`geoJson.properties.${x[x.length-1]} = doc.${x2}`)
              s2.push(`(doc.${x2}?doc.${x2}:'')`)
            })
            map_info = s2.join(" + '-' + ")
          }

          // contruct the view
          const curPath=newNamePath.join(".")
          if(newNamePath.indexOf('repeat')==-1){
            //normal case
            views[curPath]={
              // "map": "function(doc){if(doc.form_data && doc.form_data.commentaires.poly_groupe){emit(doc._id,doc.form_data.commentaires.poly_groupe);}}"
              map: `function(doc) {
                if(doc._id.indexOf('survey_')==0 && doc.form_data && doc.form_data.${curPath} && doc.survey_deleted !== true){
                  var geoJson= {};
                  geoJson.type='Feature'
                  geoJson.geometry=doc.form_data.${curPath}.geometry;
                  geoJson.properties={};
                  ${addFields.join(';')};
                  geoJson.properties.map_label = doc.form_data.${curPath}__map_info || (${map_info});
                  emit(doc._id,geoJson);
                }
              }`
            }
          }else{
            //deal with repeat...
            //  fucntion generated by Claud 3.5 sonnet, fix and modified for ad fields
            const generateLoopStructure = (path, addFields) => {
              const parts = path;
              let result = `function(doc) {
                if(doc._id.indexOf('survey_')==0 && doc.form_data && ${path.join('.').split('.repeat')[0]} && doc.survey_deleted !== true){`;
              let currentPath = [];
              let loopCount = 0;
              let indentation = '';
            
              for (let i = 0; i < parts.length; i++) {
                if (parts[i] === 'repeat') {
                  loopCount++;
                  const arrayPath = utils_gen.replaceAll(currentPath.join('.'),'.[', '[');
                  result += `${indentation}for (let i${loopCount} = 0; i${loopCount} < ${arrayPath}.length; i${loopCount}++) {`;
                  indentation += '  ';
                  currentPath.push(`[i${loopCount}]`);
                } else {
                  currentPath.push(parts[i]);
                }
              }
            
              // Add the woody_tree_pt to the array
              // var geoJson=${currentPathJoin};
              // ${addFields.join(';')};
              const currentPathJoin = utils_gen.replaceAll(currentPath.join('.'),'.[', '[');
              result += `if(${currentPathJoin}){
                      var geoJson= {};
                      geoJson.type='Feature'
                      geoJson.geometry=${currentPathJoin}.geometry;
                      geoJson.properties={};
                      ${addFields.join(';')};
                      geoJson.properties.map_label = ${currentPathJoin}__map_info || (${map_info});
                      emit(doc._id,geoJson);
                    }`;
            
              // Close the loops
              while (loopCount > 0) {
                indentation = indentation.slice(2);
                result += `${indentation}}`;
                loopCount--;
              }
              result += `}}`;
              return result;
            }
            views[curPath]={
              // "map": "function(doc){if(doc.form_data && doc.form_data.commentaires.poly_groupe){emit(doc._id,doc.form_data.commentaires.poly_groupe);}}"
              map: generateLoopStructure(['doc','form_data'].concat(newNamePath), addFields)
            }
          }

        }
      }
    })
    return views
  },
  // *************** code to generate the summary views
  getDesignSummaryViews(formDefinition,options){
    //return an array of views(to be added to a desigh doc) that should be present so summary (ies) can be in a view.
    let views={}
    if (! options){
      options={}
    }
    Object.keys(formDefinition).map(key=>{
      if(key.indexOf('summary_')==0){
        const sumName = RegExp(/_(.*)/).exec(key)[1] //https://stackoverflow.com/a/57786079/140384
        // \n replace does not work.. not sure why
        // console.log(formDefinition[key].replace('\n',''))
        // console.log(formDefinition[key].substring(26,29))
        views[sumName] =  JSON.parse(formDefinition[key])
      }
    })
    return views
  },
  computePages(formObject,formDefinition,otherData){
    if(!otherData.formDefinition){
      otherData.formDefinition=formDefinition
    }
    let form_data=formObject.form_data
    let pages=[]
    // it's an aecom appearnce parameter on each control. Define if the element ids shown on first/front page.
    // TODO: ? not supported for control inside a repeat ( do as group if we want to.)
    let frontPageControls=[] 
    //if has pages
    let parentHasPages=['pages','pagesOnlyMain'].indexOf(formDefinition.style || '')!=-1
    this.computePages_sub(formDefinition.children,form_data,form_data,formDefinition,[],pages,parentHasPages,frontPageControls,otherData)
    //if controls on front apge, add it to the list:
    if(frontPageControls.length>0){
      const frontPage={
        type:'frontPage',
        controls:frontPageControls,
        pathComplete:[]
      }
      pages.splice(0,0,frontPage)
    }
    return pages
  },
  computePages_sub(children,data,formData,formDefinition,namePath,pages,parentHasPages0,frontPageControls,otherData){
    let mainAppearance=formDefinition.style || ''
    let parentHasPages=parentHasPages0?true:false
    
    children.map(x=>{
      //caclulate some usful values
      const newNamePath=namePath.concat(x.name)
      const isRelevant=this.isRelevant(x,formData,otherData,newNamePath) && x.hasOwnProperty('label') // check label to avoid system props.

      //if front page, add ot to the list.
      if(isRelevant && this.isAecomAppearanceFrontPage(x)){ 
        frontPageControls.push(newNamePath)
      }

      if(x.hasOwnProperty('children')){
        //should always have a name if we have children.

        // child has pages:
        let childPages=parentHasPages ? true: false
        if (mainAppearance=='pagesOnlyMain'){
          //only main pages are only at the top level pages. so direct child of the form definition. 
          childPages=false
        }
        // appearance can also determine if has children pages
        const appearance1= x.control?x.control.appearance || '':''
        if (['field-list','compact'].indexOf(appearance1)!=-1){
          // no pages
          childPages=false
        }
        //Verify if relevant...
        if(!isRelevant || parentHasPages==false){
          //relevant, but no page at all
          //pages.push({path:namePath,name:x.name,repeatNew:true,pathComplete:namePath.concat(x.name)})
        }else{
          // we have pages
          if(x.type=='repeat'){
            if(childPages==true){
              if(data[x.name].length>0){
                //loop throw each of the repeat
                data[x.name].map((y,y2)=>{
                  const newNamePathRepeat=newNamePath.concat(y2)
                  if(childPages==true){
                    this.computePages_sub(x.children,y,formData,formDefinition,newNamePathRepeat,pages,childPages,frontPageControls,otherData) // call ourself on sub group
                  }else{
                    //we have pages, so add one for this one
                    pages.push({path:namePath,name:x.name,repeatItem:y2,pathComplete:newNamePathRepeat})
                  }
                })
              }
              //push a page so we can add more.
              pages.push({path:namePath,name:x.name,repeatNew:true,pathComplete:newNamePath})
            }else{
              //all on one page
              pages.push({path:namePath,name:x.name,repeatNew:true,pathComplete:newNamePath})
            }
          }else{ // it's a group
            if(childPages==true){
              this.computePages_sub(x.children,data[x.name],formData,formDefinition,newNamePath,pages,childPages,frontPageControls,otherData) // call ourself on sub group
            }else{
              //we have pages, so add one for this one
              pages.push({path:namePath,name:x.name,pathComplete:newNamePath})
              // to gather front page control, pass an empty array.
              this.computePages_sub(x.children,data[x.name],formData,formDefinition,newNamePath,[],childPages,frontPageControls,otherData) 
            }            
          }
        }
      }else if(x.hasOwnProperty('name') && isRelevant){ 
        if(parentHasPages){
          //add a page for this control
          pages.push({path:namePath,name:x.name,pathComplete:newNamePath})
        }
      }
    })
  },
  computePages_frontPage_sub(){

  },
  isAecomAppearanceFrontPage(xControl){
    if(xControl.hasOwnProperty('appearance_aecom')){
      const appearances=xControl.appearance_aecom.split(';')
      if(appearances.indexOf('front_page')>-1){
        return true
      }
    }
    return false
  },
  // calculte the calculated fields
  calculateValues(formObject,formDefinition,otherData){
    let form_data=formObject.form_data
    let form_status=formObject.form_status
    //calculate sometimes need access to the formDefinition... procide it thought otherData, easier that re-implement many functions
    otherData.formDefinition=formDefinition
    this.calculateValues_sub(formDefinition.children,form_data,form_status,form_data,formDefinition,[],otherData, formObject._id)
  },
  calculateValues_sub(children,data,status,formData,formDefinition,namePath,otherData,parentRepeatName){
    if(!status){
      status={}
    }
    children.map(x=>{
      //todo: get the default value
      const newNamePath=namePath.concat(x.name)
      if(x.hasOwnProperty('children')){
        //should always have a name if we have children.
        if(x.type=='repeat'){
          //Verify if relevant...
          if(status && status[x.name]!=4){
            //loop throw each of the repeat and do calculation
            if(!data){
              data={}
            }
            if(!data[x.name]){
              data[x.name]=[]
            }
            for(let y=0;y<data[x.name].length;y++){ // for loop as we want ref to data
              const newNamePathRepeat=newNamePath.concat(y)
              data[x.name][y].repeat_id = parentRepeatName + '_' + (y+1)
              this.calculateValues_sub(x.children,data[x.name][y],status[x.name]?.[y],formData,formDefinition,newNamePathRepeat,otherData, data[x.name][y].repeat_id) // call ourself on sub group
            }
          }
        }else{ // it's a group
          if(status && status[x.name]!=4){
            this.calculateValues_sub(x.children,data[x.name],status[x.name],formData,formDefinition,newNamePath,otherData, parentRepeatName) // call ourself on sub group
          } 
        }
      }else if(x.name && x.bind && x.bind.calculate && x.name!='instanceID' && status[x.name]!=4){ 
        // InstanceID is auto put in our form definition, but we don't use it...
        // check the status before calculate... not relevant(4), we don't calculate.
        // calculate the value
        let do1=true
        if(x.condition){
          //check condition before do the calculation
          do1=expr.evaluateExprString(formData,x.condition,otherData,newNamePath)
        }
        if(do1){
          //do the calculation
          const newVal=expr.evaluateExprString(formData,x.bind.calculate,otherData,newNamePath)
          if(data[x.name]!==newVal){
            //if value change, change the value
            data[x.name]=newVal
            this.resetOtherDataValues(otherData)
          }
        }
      }else if(x.name && x.bind && x.calculation_map_info && x.name!='instanceID' && status[x.name]!=4){ 
        // **** Caclulation for calculation_map_info => calculate the info text that will be displayed on the map
        const newVal=expr.evaluateExprString(formData,x.calculation_map_info,otherData,newNamePath)
        const fieldName=x.name + '__map_info'
        if(data[fieldName]!==newVal){
          data[fieldName]=newVal
        }
      }else if(x.name && x.default && status[x.name]!=4){
        // calculate the default value if condition is ok.
        // usually the condition specify that value should be null for the condition to be ok, so avoid changing it
        // check condition before do the calculation
        let doDefault=false
        if(x.condition && expr.evaluateExprString(formData,x.condition,otherData,newNamePath)
          && (status[x.name]==2 || !data[x.name] )
        ){
          //do the calculation
          doDefault=true
        }else if(!data?.[x.name] && data?.[x.name]!==true && data?.[x.name]!==false){
          // if had a value and now not - do not update:
          if(utils_gen.getPropTree2(otherData.oldSurvey,newNamePath)){
            doDefault=false
          }else{
            doDefault=true
          }
        }else if(status?.[x.name]==2){
          doDefault=true
        }
        if(doDefault && typeof x.default === 'string' && x.default.indexOf('*calculated')===0){
          // we have a defulat calculated value that we can update
          const newVal=this.getDefaultValue(data[x.name],x,formData,formDefinition,otherData,newNamePath)
          if(newVal!== undefined && data[x.name]!=newVal){
            data[x.name]=newVal
            status[x.name]==2 // it's the default value
            this.resetOtherDataValues(otherData)
          }
        }
      }
    })
  },
  setStatusDataDefault(formObject,formDefinition,otherData){
    let form_data=formObject.form_data
    let form_status=formObject.form_status
    this.setStatusDataDefault_sub(formDefinition.children,form_data,form_status,form_data,formDefinition,[],otherData)
  },
  setStatusDataDefault_sub(children,data,status,formData,formDefinition,namePath,otherData){
    children.map(x=>{
      //todo: get the default value
      const newNamePath=namePath.concat(x.name)
      if(x.hasOwnProperty('children')){
        //should always have a name if we have children.
        if(x.type=='repeat'){
          //Verify if relevant...
          if(status && status[x.name]!=4){
            if(!data){
              data={}
            }
            if(!data[x.name]){
              data[x.name]=[]
            }
            if(!status[x.name] || Array.isArray(status[x.name]) == false){
              status[x.name]=[]
            }
            //loop throw each of the repeat and do calculation
            for(let y=0;y<data[x.name].length;y++){ // for loop as we want ref to data
              const newNamePathRepeat=newNamePath.concat(y)
              if (!status[x.name][y]){
                status[x.name].push({})
              }
              this.setStatusDataDefault_sub(x.children,data[x.name][y],status[x.name][y],formData,formDefinition,newNamePathRepeat,otherData) // call ourself on sub group
            }
          }
        }else{ // it's a group
          if(status && status[x.name]!=4){
            // TODO: verify if it's the correct solution... seem to avoid bug at least!
            if(data){
              this.setStatusDataDefault_sub(x.children,data[x.name],status[x.name],formData,formDefinition,newNamePath,otherData) // call ourself on sub group
            }else{
              status[x.name] = 20
            }
          } 
        }
      }else if(x.name && status && status[x.name] && status[x.name]==2 && x.default){ // it's a default value - calculated or not
        // it's default
        // COMPARE VALUES old vs new
        if(data[x.name]!=utils_gen.getPropTree2(otherData.oldSurvey,newNamePath) ){
          //set the status at 0 if different
          status[x.name]=0
        }
      }
    })
  },
  getEditField(formDefinition,outLabels, outConfig){
    // outconfig will hold a reference to the field object, with all props...
    let out=[]
    this.getEditField_sub(formDefinition.children,formDefinition,[],out,outLabels)
    return out
  },
  getEditField_sub(children,formDefinition,namePath,out,outLabels,outConfig){
    children.map(x=>{
      //todo: get the default value
      const newNamePath=namePath.concat(x.name)
      if(x.hasOwnProperty('children')){
        //should always have a name if we have children.
        if(x.type=='repeat'){
          // TODO: Support repeat, but have many limitations - we execute this on couchdb server - more complicated methods
          // not supported as of now
        }else{ // it's a group
          this.getEditField_sub(x.children,formDefinition,newNamePath,out,outLabels) // call ourself on sub group
        }
      }else if(x.name && x.list_aecom && ['true','yes'].indexOf(x.list_aecom.toLowerCase())>-1){ 
        out.push(newNamePath)
        if(outLabels){
          outLabels.push(x.label)
        }
        if(outConfig){
          outConfig.push(x)
        }
      }
    })
  },
  getFieldsFlatten(formDefinition, includeGroupsRepeats=false){
    // outconfig will hold a reference to the field object, with all props...
    let out=[]
    this.getFieldsFlatten_sub(formDefinition.children,formDefinition,[],out, includeGroupsRepeats)
    return out
  },
  getFieldsFlatten_sub(children,formDefinition,namePath,out, includeGroupsRepeats){
    children.map(x=>{
      //todo: get the default value
      const newNamePath=namePath.concat(x.name)
      if(x.hasOwnProperty('children')){
        //should always have a name if we have children.
        if(x.type=='repeat'){
          const newNamePath2=newNamePath.slice()
          newNamePath2.push('repeat')
          this.getFieldsFlatten_sub(x.children,formDefinition,newNamePath2,out, includeGroupsRepeats) // call ourself on sub repeat
        }else{ // it's a group
          this.getFieldsFlatten_sub(x.children,formDefinition,newNamePath,out, includeGroupsRepeats) // call ourself on sub group
        }
        if(includeGroupsRepeats){
          // const x2 = JSON.parse(JSON.stringify(x))
          // delete x2.children
          out.push({path:newNamePath, field: x}  )
        }
      }else if(x.name){ 
        out.push({path:newNamePath, field: x}  )
      }
    })
  },
  genericLoopFunction(formDefinition,functionToExecute,options){
    // will run the loop, the options object should be used to gather the results as it's the second parameter to the functionToExecute call.
    return this.genericLoopFunction_sub(formDefinition.children,formDefinition,[],functionToExecute,options)
  },
  genericLoopFunction_sub(children,formDefinition,namePath,functionToExecute,options){
    let views={}
    children.map(x=>{
      const newNamePath=namePath.slice()
      newNamePath.push(x.name)
      if(x.hasOwnProperty('children')){
        //should always have a name if we have children.
        if(x.type=='repeat'){
          const newNamePath2=newNamePath.slice()
          newNamePath2.push('repeat')
          this.genericLoopFunction_sub(x.children,formDefinition,newNamePath2,functionToExecute,options) // call ourself on sub group
        }else{
          this.genericLoopFunction_sub(x.children,formDefinition,newNamePath,functionToExecute,options) // call ourself on sub group
        }
      }else if(x.hasOwnProperty('name')){
        functionToExecute(x,options)
      }
    })
  },
  getFieldFromDefinition(formDefinition,name){
    const findByName = (children,name,namePath)=>{
      for (let i = 0; i < children.length; i++) {
        const x = children[i]
        let newNamePath=namePath.concat(x.name)
        if(x.hasOwnProperty('name') && x.name == name){
          return Object.assign({namePath: newNamePath},x) // namepath useful to knwo where to find it in the data.
        }
        if(x.hasOwnProperty('children')){
          if(x.type=='repeat'){
            newNamePath=newNamePath.concat('repeat')
          }
          const x2 = findByName(x.children,name,newNamePath)
          if(x2){ return x2}
        }
      }
    }
    return findByName(formDefinition.children,name,[])
  },
  resetOtherDataValues(otherData){
    // because when we do calculation or new edit, we have to ensure we have the most up to data data.
    // remove everything myDataFlattenObjectNoTree
    delete otherData.myDataFlattenObjectNoTree
    // remove every type1 and type2
    let keys=Object.keys(otherData).filter(x=> x.indexOf('type1') == 0 || x.indexOf('type2') == 0 )
    keys.map(x=>{
      delete otherData[x]
    })
  },
  setSurveyDocStatus(surveyDoc, formDefinition, otherData = null, surveyDocVueReactiveObject = true){
    if (!surveyDoc.form_status){
      //  when importing third party form, the status is not calculate, calculate it
      surveyDoc.form_status={}
    }
    if (! otherData){
      otherData={}
      otherData.oldSurvey = surveyDoc.form_data
      otherData.oldSurveyStatus = surveyDoc.form_status
      otherData.formDefinition = formDefinition
    }
    // TODO: avoid 2 times calculate status.
    // have to claculate status before, mostly when repeat or group status change because of a value change. and them calculated value can use them...
    let beforeUpdate=null;
    let doGlobalVals=false // ensure to not calculate global status more than once or twice if needed.
    do {
      beforeUpdate = JSON.stringify(surveyDoc)
      let o1 = Object.assign({}, otherData) // avoid resetting and more sure it's brand new...
      this.setStatusDataDefault(surveyDoc, formDefinition, o1)
      this.calculateValues(surveyDoc, formDefinition, o1)
      this.getStatusData(surveyDoc, formDefinition, o1)
      // reset the other data:
      // formUtils.resetOtherDataValues(this.otherData)
      
      // We put the status in the form_status_global *** in the loop, unlees not reactive... And those values can be used in other calculated values...
      if(doGlobalVals){
        const form_status_global = this.getStatusOfTree(surveyDoc.form_status)
        let survey_valid = false
        if(form_status_global < 10){
          survey_valid = true
        }
        if (surveyDocVueReactiveObject){
          // make it reactive in the computed prop...
          // https://vuejs.org/v2/guide/list.html#Object-Change-Detection-Caveats
          Vue.set(surveyDoc, 'form_status_global', form_status_global) 
          Vue.set(surveyDoc, 'survey_valid', survey_valid) 
        }else{
          surveyDoc.form_status_global = form_status_global
          surveyDoc.survey_valid = survey_valid
        }
        if(otherData.flagsEnabled){
          this.setSurveyFlagStatus(surveyDoc, formDefinition, otherData, surveyDocVueReactiveObject)
        }
        if(beforeUpdate == JSON.stringify(surveyDoc)){
          break
        }
      }
      if(doGlobalVals===false && beforeUpdate == JSON.stringify(surveyDoc)){
        doGlobalVals=true
      }
    } while (true)

  },
  setSurveyFlagStatus(surveyDoc, formDefinition, otherData = null, surveyDocVueReactiveObject = true){
    // TODO: check if flag are enabled... we do it in the store... For now we do it in the edit.vue... but not ideal...
    // TODO: with previous, we should use the fucntion in flagsStatusByPath in the store...
    const data = utils_gen.flattenObjectArrayWithPath(surveyDoc.form_data)
    const dataStatus = utils_gen.flattenObjectArrayWithPath(surveyDoc.form_status)
    const dataFlags = Object.keys(data).filter(x=> (x.endsWith("__flag.status"))).filter(x=>{
      // First of all, if value not more relevant, don't take into account the flag.
      const x2 = x.replace('__flag.status','')
      if(dataStatus[x2] == 4){
        // So it's not relevant... delete the flag or don't take it into account....
        return false
      }
      return true
    }).map(x=>data[x])
    let flagStatus = null
    if(dataFlags.indexOf('flag-validated')!=-1){
      flagStatus='flag-validated'
    }
    if(dataFlags.indexOf('flag-solved')!=-1){
      flagStatus='flag-solved'
    }
    if(dataFlags.indexOf('flag-raised')!=-1){
      flagStatus='flag-raised'
    }
    if (surveyDocVueReactiveObject){
      // make it reactive in the computed prop...
      // https://vuejs.org/v2/guide/list.html#Object-Change-Detection-Caveats
      Vue.set(surveyDoc, 'form_flags_status_global', flagStatus) 
    }else{
      surveyDoc.form_flags_status_global = flagStatus
    }
  },
  logStatus(mess){
    // console.log(mess)
  },
  log(mess){
    // console.log(mess)
  },

}
